<template>
  <div class="events-block">
    <div class="header">
      <div class="navigator-bar">
        <span @click="prevSlide()" class="icon-arrow_prev"></span>
        <span @click="nextSlide()"><i class="fas fa-arrow-alt-circle-right"></i></span>
      </div>
    </div>
    <div class="slider">
      <div class="wrapper" :style="wrapStyleObj">
        <div v-for="relation in releations" :key="relation" class="relation-box" :data-parent="relation.parent" :data-child="relation.child" :style="relation.styleObj"></div>
        <div
            v-for="month in year"
            :key="month.number"
            :data-month="month.number"
            class="element"
            :style="elementStyleObj">
          <span class="slide-title">{{ month.name }}</span>
          <div class="wrapper">
            <div class="today-line" :style="todayLineStyleObj">
              <div class="day-in-circle" :style="dayStyleObj">{{ today.day }}</div>
              <div class="vertical-line" :style="lineStyleObj"></div>
            </div>
            <div v-for="event in events" :key="event.name">
              <template v-if="(event?.date?.month == month.number) && (event?.hasChildren || event?.hasParent)">
                <div class="event" :data-event="event.id" :style="eventStyleObj[event.id]">
                  {{ event.name }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  data() {
    return {
      /* Параметры календаря */
      year: [
        {
          name: 'Январь',
          number: 1,
          dayCount: 31
        },
        {
          name: 'Февраль',
          number: 2,
          dayCount: 28
        },
        {
          name: 'Март',
          number: 3,
          dayCount: 31
        },
        {
          name: 'Апрель',
          number: 4,
          dayCount: 30
        },
        {
          name: 'Май',
          number: 5,
          dayCount: 31
        },
        {
          name: 'Июнь',
          number: 6,
          dayCount: 30
        },
        {
          name: 'Июль',
          number: 7,
          dayCount: 31
        },
        {
          name: 'Август',
          number: 8,
          dayCount: 31
        },
        {
          name: 'Сентябрь',
          number: 9,
          dayCount: 30
        },
        {
          name: 'Октябрь',
          number: 10,
          dayCount: 31
        },
        {
          name: 'Ноябрь',
          number: 11,
          dayCount: 30
        },
        {
          name: 'Декабрь',
          number: 12,
          dayCount: 31
        },
      ],
      today: {
        day: 23,
        month: 2,
        year: 2022,
      },

      /* Свойства слайдера */
      parentElementSelector: '.slider',
      elementsCount: 3,
      elementWidth: 0,
      left: 0,
      wrapStyleObj: {},
      elementStyleObj: {},

      /* Свойства дня */
      todayLineStyleObj: {},
      dayStyleObj: {},
      lineStyleObj: {},

      /* Свойства события */
      events: [
        {
          id: 1,
          name: 'Событие 1',
          date: {
            day: 23,
            month: 2,
            year: 2022,
          },
          hasChildren: true,
          children: [
            {
              id: 2,
              date: {
                day: 2,
                month: 3,
                year: 2022,
              }
            }
          ]
        },
        {
          id: 2,
          name: 'Событие 2',
          date: {
            day: 2,
            month: 3,
            year: 2022,
          },
          hasParent: true,
        },
        {
          id: 3,
          name: 'Событие 3',
          date: {
            day: 27,
            month: 2,
            year: 2022,
          },
          hasParent: true,
        },
      ],
      monthEventCount: {},
      releations: [],
      eventStyleObj: [],
    }
  },
  mounted() {
    this.calcParams();
    this.setDay(this.today);

    this.prepareEvents();

    this.setEventStyle(this.events);

    this.createRelation();
  },
  methods: {

    /* НАЧАЛО: Методы слайдера */

    calcParams: function () {

      /*
       * Функция высчитывает параметры слайдера
       */

      let parentWidth = $(this.parentElementSelector).outerWidth();

      this.elementWidth = parentWidth / this.elementsCount;
      this.elementStyleObj.width = this.elementWidth + 'px';
    },
    nextSlide: function () {
      let elementsCount = $(this.parentElementSelector + ' .wrapper .element').length,
          maxMargin = ((elementsCount - this.elementsCount) * this.elementWidth) - this.elementWidth;

      if (maxMargin >= this.left) {
        this.left += this.elementWidth;
        this.wrapStyleObj.marginLeft = -this.left + 'px';
      }
    },
    prevSlide: function () {
      if (this.left > 0) {
        this.left -= this.elementWidth;
        this.wrapStyleObj.marginLeft = -this.left + 'px';
      }
    },

    /* КОНЕЦ: Методы слайдера */

    /* НАЧАЛО: Методы календаря */

    setDay: function (date) {
      if (date.month) {
        let daysInThisMonth     = this.year[date.month - 1].dayCount,
            month               = $(`.slider .wrapper .element[data-month=${date.month}] .wrapper`),
            monthBlockWidth     = month.outerWidth(),
            monthBlockHeight    = month.outerHeight(),
            dayInThisMonthWidth = monthBlockWidth / daysInThisMonth,
            dayPosition         = dayInThisMonthWidth * (date.day);

        this.elementWidth = monthBlockWidth;

        month.find('.today-line').css('display', 'block');
        this.todayLineStyleObj.left = dayPosition - 12 + 'px';
        this.lineStyleObj.height = monthBlockHeight + 'px';
      }
    },

    setEventStyle: function (events) {
      for (let key in events) {
        this.eventStyleObj[events[key].id] = this.getEventStyle(events[key].date, events[key].position);
      }
    },

    getEventStyle: function (date, position) {
      let styleObj = {};

      if (date.month && date.day) {
        let daysInThisMonth     = this.year[date.month - 1].dayCount,
            month               = $(`.slider .wrapper .element[data-month=${date.month}] .wrapper`),
            monthBlockWidth     = month.outerWidth(),
            //monthBlockHeight    = month.outerHeight(),
            dayInThisMonthWidth = monthBlockWidth / daysInThisMonth,
            dayPosition         = dayInThisMonthWidth * (date.day);

        let halfMonth = Math.floor(daysInThisMonth / 2);

        if (date.day > halfMonth)
          styleObj.left = dayPosition - monthBlockWidth/2 + 'px';
        else
          styleObj.left = dayPosition + 'px';
      }

      if (position > 1) {
        styleObj.top = 125 * (position - 1) + 'px';
      }
      return styleObj;
    },

    prepareEvents: function () {
      let monthCount = [];

      for (let event in this.events) {
        if (monthCount[this.events[event]?.date?.month]) {
          ++monthCount[this.events[event].date.month];
        } else {
          monthCount[this.events[event].date.month] = 1;
        }

        this.events[event].position = monthCount[this.events[event].date.month];

        // Проверяем наличие дочерних элементов
        if (this.events[event]?.children) {
          for (let child in this.events[event].children) {
            if (this.events[event].date.month !== this.events[event].children[child].date.month) {
              ++monthCount[this.events[event].date.month];

              if (monthCount[this.events[event].children[child].date.month]) {
                ++monthCount[this.events[event].children[child].date.month];
              } else {
                monthCount[this.events[event].children[child].date.month] = 1;
              }

              this.releations.push({
                parent: this.events[event].id,
                child: this.events[event].children[child].id,
                styleObj: {
                  left: '200px'
                },
              });
            }
          }
        }
      }
    },

    createRelation: function () {
      for (let key in this.releations) {
        let parentId = this.releations[key].parent,
            childId = this.releations[key].child;

        let parentDomElement = $(`.event[data-event=${parentId}]`),
            childDomElement = $(`.event[data-event=${childId}]`);

        let parentMonth = parentDomElement.parents('.element').data('month'),
            childrenMonth = childDomElement.parents('.element').data('month');

        let parentLeft = this.eventStyleObj[parentId].left,
            childrenLeft = this.eventStyleObj[childId].left;

        let parentTop = this.eventStyleObj[parentId].top,
            childrenTop = this.eventStyleObj[childId].top;

        if (parentTop == undefined) {
          parentTop = 0 + 'px';
        }

        let parentStartPoint = ((parentMonth - 1) * this.elementWidth + Number(parentLeft.replace('px', ''))) + this.elementWidth / 4;
        let childStartPoint = (childrenMonth - 1) * this.elementWidth + Number(childrenLeft.replace('px', ''));

        let difference =  childStartPoint - parentStartPoint + 10;

        console.log(parentTop, childrenTop);
        console.log(parentDomElement.height());

        this.releations[key].styleObj.top = Number(parentTop.replace('px', '')) + 120 + 35 + 'px';
        this.releations[key].styleObj.left = parentStartPoint + 'px';
        this.releations[key].styleObj.width = difference + 'px';
        this.releations[key].styleObj.height = 120 / 2 + 5 + 'px';

        console.log(childrenTop);
     //   console.log(this.releations[key]);

       // let currentRelation = $(`.relation-box`);

      //  console.log(parentStartPoint, childStartPoint);
        //console.log(currentRelation);
        //currentRelation.css('left', parentStartPoint)

        //let currentDomElement = $(`.event[data-event=${parentId}]`)

        //let difference = parentStartPoint - childStartPoint;

        //this.releations[key].styleObj.left = childStartPoint;
        //this.releations[key].styleObj.width = difference;

       // console.log(this.releations);

        //console.log(difference );
       // console.log(parentStartPoint, childStartPoint);
       // offsetLeft
        //let parentStartPoint = childrenMonth * this.elementWidth ;
//console.log(parentLeft, childrenLeft);
//console.log(parentMonth, childrenMonth);
        // console.log(this.elementWidth/2);
        // console.log(parentDomElement, childDomElement);
      }
    },

    /* КОНЕЦ: Методы календаря */
  },

}
</script>

<style scoped>
  .events-block {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .events-block .header {
    position: relative;
  }

  .events-block .header .navigator-bar {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 25px;
    color: var(--third-text-color);
    background: var(--main-bg-color);
    z-index: 5;
  }

  .events-block .header .navigator-bar span i {
    margin-left: 5px;
    cursor: pointer;
  }

  .slider {
    width: 100%;
    overflow: hidden;
  }

  .slider > .wrapper {
    width: 400%;
    min-height: 500px;
    display: inline-flex;
    transition: 1s;
    position: relative;
  }

  .element {
    width: 33%;
    padding-right: 2px;
  }

  .element > .wrapper {
    position: relative;
    height: 100%;
    background: #dbd6d6;
  }

  .slide-title {
    font-size: 18px;
    line-height: 35px;
  }

  /* НАЧАЛО: Линия с текущей датой */

  .today-line {
    position: absolute;
    top: -30px;
    display: none;
  }

  .today-line .day-in-circle {
    width: 30px;
    height: 30px;
    background: var(--main-block-color);
    color: var(--second-text-color);
    text-align: center;
    line-height: 28px;
    border-radius: 50px;
    margin-left: -4px;
  }

  .today-line .vertical-line {
    width: 1px;
    background: var(--main-block-color);
    margin-left: 11px;
  }

  /* КОНЕЦ: Линия с текущей датой */

  /* НАЧАЛО: Блок с событием */
  .event {
    height: 100px;
    width: calc(50% - 20px);
    background: var(--main-block-color);
    color: var(--second-text-color);
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    z-index: 1;
  }
  /* КОНЕЦ: Блок с событием */


  .relation-box {
    width: 300px;
    height: 100px;
    border-bottom: 1px dashed var(--main-block-color);
    position: absolute;
    top: 150px;
    z-index: 1;
    left: 600px;
    border-left: 1px dashed var(--main-block-color)
  }
</style>